<template>
  <div class="user-layout-register">
    <a-form ref="formRegister" :form="form" id="formRegister">
      <div class="form-scroll">
        <a-form-item class="allInputBox">
          <a-input
            size="large"
            type="text"
            placeholder="请输入邮箱"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: $t('user.email.required'),
                    pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                  }
                ],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="allInputBox">
          <a-input-password
            size="large"
            @click="handlePasswordInputClick"
            placeholder="请输入密码"
            v-decorator="[
              'password',
              {
                rules: [{ required: true, message: '请输入密码' }, { validator: this.handlePasswordLevel }],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input-password>
        </a-form-item>
        <a-form-item class="allInputBox">
          <a-input-password
            size="large"
            placeholder="请输入确认密码"
            v-decorator="[
              'confirmPassword',
              {
                rules: [{ required: true, message: '请输入确认密码' }, { validator: this.handlePasswordCheck }],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input-password>
        </a-form-item>
        <a-form-item class="allInputBox">
          <a-input
            size="large"
            type="text"
            placeholder="请输入公司名称"
            v-decorator="[
              'company',
              { rules: [{ required: true, message: '请输入公司名称' }], validateTrigger: ['change', 'blur'] }
            ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="allInputBox">
          <a-input
            size="large"
            type="text"
            placeholder="请输入联系人"
            v-decorator="[
              'contact',
              { rules: [{ required: true, message: '请输入联系人' }], validateTrigger: ['change', 'blur'] }
            ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="allInputBox">
          <a-input
            size="large"
            type="text"
            placeholder="请输入手机号码"
            v-decorator="[
              'mobile',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入正确的手机号',
                    pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                  },
                  { validator: this.handlePhoneCheck }
                ],
                validateTrigger: ['change', 'blur']
              }
            ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item>
          <div class="code allInputBox">
            <a-input
              autocomplete="off"
              size="large"
              placeholder="请输入验证码"
              ref="codeInput"
              v-decorator="['code', { rules: [{ required: true, message: '请输入验证码' }], validateTrigger: 'blur' }]"
            ></a-input>
            <img @click="getCode" :src="codeUrl" alt="" />
          </div>
        </a-form-item>
      </div>
      <div style="margin: 0 0 15px 0" class="medium-size">
        <a-checkbox @change="changeAgree">
          我已阅读并同意<span class="primary-text" @click.stop.prevent="skipTo(1)" style="margin: 0 5px">服务条款</span
          >和<span @click.stop.prevent="skipTo(2)" style="margin: 0 5px" class="primary-text">隐私政策</span>
        </a-checkbox>
      </div>
      <a-form-item>
        <div class="btn-box">
          <a-button
            size="large"
            type="primary"
            htmlType="submit"
            class="register-button"
            :loading="registerBtn"
            @click.stop.prevent="handleSubmit"
            :disabled="registerBtn"
          >{{ $t('user.register.register') }}
          </a-button>
          <a-checkbox class="phone-checkbox" @change="changeAgree">
            我已阅读并同意<span
              class="primary-text"
              @click.stop.prevent="skipTo(1)"
              style="margin: 0 5px"
            >服务条款</span
            >和<span @click.stop.prevent="skipTo(2)" style="margin: 0 5px" class="primary-text">隐私政策</span>
          </a-checkbox>
          <router-link class="login" :to="{ name: 'login' }">{{ $t('user.register.sign-in') }}</router-link>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { register, getCodeImg } from '@/api/login'
import { deviceMixin } from '@/store/device-mixin'
import { scorePassword } from '@/utils/util'
import { Encrypt } from '@/utils/jsencrypt'
import { mapState } from 'vuex'
export default {
  name: 'Register',
  components: {},
  mixins: [deviceMixin],
  data () {
    return {
      codeUrl: undefined,
      uuid: undefined,
      isAgree: false,
      form: this.$form.createForm(this),
      channelOptions: [
        { value: 1, label: '展会沙龙' },
        { value: 2, label: '商务引荐' },
        { value: 3, label: '搜索引擎' },
        { value: 6, label: ' 其他途径' }
      ],
      state: {
        time: 60,
        level: 0,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000'
      },
      registerBtn: false
    }
  },
  computed: {
    ...mapState({
      serviceAgreement: (state) => state.autoweb.serviceAgreement,
      privacyAgreement: (state) => state.autoweb.privacyAgreement
    })
  },
  created () {
    this.getCode()
  },
  methods: {
    // 获取验证图片
    getCode () {
      getCodeImg().then((res) => {
        this.codeUrl = 'data:image/gif;base64,' + res.data.img
        this.uuid = res.data.uuid
        // 更新图片后聚焦
        // this.$nextTick(() => {
        //   this.$refs.codeInput.focus()
        // })
      })
    },
    changeAgree (e) {
      this.isAgree = e.target.checked
    },
    skipTo (e) {
      window.open(e === 1 ? this.serviceAgreement : this.privacyAgreement, '_block')
    },
    handlePasswordLevel (rule, value, callback) {
      if (!value) {
        return callback()
      }
      // const str = /^(?![A-Za-z0-9]+$)(?![a-z0-9\W]+$)(?![A-Za-z\W]+$)(?![A-Z0-9\W]+$)[a-zA-Z0-9\W]{8,}$/
      // const reg = new RegExp(str)
      if (value.length >= 8) {
        // if (value.length >= 8 && reg.test(value)) {
        if (scorePassword(value) >= 30) {
          this.state.level = 1
        }
        if (scorePassword(value) >= 60) {
          this.state.level = 2
        }
        if (scorePassword(value) >= 80) {
          this.state.level = 3
        }
      } else {
        this.state.level = 0
        callback(new Error('密码不低于8位'))
        // callback(new Error('密码不低于8位，包含大小写字母、数字特殊符号'))
      }
      this.state.passwordLevel = this.state.level
      this.state.percent = this.state.level * 33
      callback()
    },
    handlePasswordCheck (rule, value, callback) {
      const password = this.form.getFieldValue('password')
      if (value === undefined) {
        callback(new Error(this.$t('user.password.required')))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('密码与确认密码不一致'))
      }
      callback()
    },
    handlePhoneCheck (rule, value, callback) {
      callback()
    },
    handlePasswordInputClick () {
      if (!this.isMobile) {
        this.state.passwordLevelChecked = true
        return
      }
      this.state.passwordLevelChecked = false
    },

    handleSubmit () {
      if (!this.isAgree) {
        return this.$message.warning('请先阅读并同意服务条款和隐私政策')
      }
      const {
        form: { validateFields },
        state
      } = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          const { password, confirmPassword } = values
          if (password !== confirmPassword) {
            return this.$message.error('密码与确认密码不一致')
          }
          state.passwordLevelChecked = false
          // $router.push({ name: 'registerResult', params: { ...values } })
          this.registerBtn = true
          this.registerFn({
            ...values,
            uuid: this.uuid,
            password: Encrypt(values.password),
            confirmPassword: Encrypt(values.confirmPassword)
          })
        }
      })
    },
    // 判断移动端
    JudgePhone () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    // 注册
    async registerFn (data) {
      data = {
        ...data,
        kind: this.JudgePhone() ? 1 : 0, // 浏览器判断手机1还是PC0
        bd_vid: sessionStorage.getItem('bd_vid') || undefined, // 得到bd_vid
        bd_type: sessionStorage.getItem('bd_vid') ? 1 : undefined
      }
      const res = await register(data)
      if (res.code === 200) {
        this.$message.success('注册成功')
        this.$router.push({ path: '/user/login' })
      } else {
        this.getCode()
      }
      this.registerBtn = false
    }
  },
  watch: {}
}
</script>
<style lang="less">
.user-register {
  &.error {
    color: #ff0000;
  }
  &.warning {
    color: #ff7e05;
  }
  &.success {
    color: #52c41a;
  }
}
.user-layout-register {
  .ant-input-group-addon:first-child {
    background-color: #fff;
  }
  .form-scroll {
    min-height: 370px;
    padding: 3px 5px;
  }
}
</style>
<style lang="less" scoped>
.user-layout-register {
  & > h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .code {
    display: flex;
    align-items: center;
    input {
      width: 60%;
      margin-right: 20px;
      border-radius: 12px;
      // border: none;
      padding: 25px;
    }
    img {
      width: 0;
      flex-grow: 1;
      height: 50px;
      border-radius: 12px;
      margin-top: -12px;
    }
  }
}
::v-deep {
  .allInputBox {
    input {
      background: #fafafa;
      outline: 1px solid #eee;
      border-radius: 12px;
      border: none;
      padding: 25px;
      margin-bottom: 10px;
      color: #999999;
    }
    input:-webkit-autofill,
    input[type='password'] {
      /* 变成透明背景色的固定写法，只针对表单自动填充的背景色 */
      transition: background-color 5000s ease-in-out 0s;
    }
    input:hover {
      background: #eeeeee;
    }
    .ant-select {
      .ant-select-selection {
        // height: 50px;
        padding: 25px 15px;
        border-radius: 12px;
        background: #fafafa;
        outline: 1px solid #eee;
        border: none;
      }
      .ant-select-selection-selected-value {
        margin-top: -18px;
      }
    }
  }
  .ant-form-explain {
    margin-left: 10px;
  }
  .medium-size {
    @media (max-width: 998px) {
      display: none;
    }
  }
  .phone-checkbox {
    display: none;
  }
  .btn-box {
    .register-button {
      width: 50%;
      height: 45px;
      border-radius: 12px;
    }
    .login {
      float: right;
      line-height: 40px;
    }
    @media (max-width: 998px) {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      .register-button {
        width: 100%;
      }
      .phone-checkbox {
        display: block;
        font-size: 3.2vw;
        text-align: center;
        margin-top: 15px;
      }
      .login {
        font-size: 3.5vw;
        text-align: center;
      }
    }
  }
}
</style>
